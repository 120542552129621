@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "DosisMedium";
  src: local("DosisMedium"),
    url("./fonts/dosis/Dosis-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "DosisBold";
  src: local("DosisBold"),
    url("./fonts/dosis/Dosis-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "DosisSemiBold";
  src: local("DosisSemiBold"),
    url("./fonts/dosis/Dosis-SemiBold.ttf") format("truetype");
}

@font-face {
  font-family: "DosisBook";
  src: local("DosisBook"),
    url("./fonts/dosis/Dosis-Book.ttf") format("truetype");
}

body {
  margin: 0;
  font-family: "DosisSemiBold" !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.container {
  position: relative;
  padding: 0px 150px;
}

.tab {
  padding: 15px 10px;
  border-radius: 5px;
  background-color: black;
}

.tab ul {
  margin: 0px;
}

.tagItem {
  padding: 5px 10px;
  background-color: #333;
  color: #999;
  border-radius: 5px;
  display: block;
  width: fit-content;
  font-weight: bold;
  margin: 10px;
  display: inline-block;
  cursor: pointer;
}

.select_item {
  padding: 5px 10px;
  background-color: #333;
  color: #999 !important;
  border-radius: 5px;
  display: block;
  width: fit-content;
  display: inline-block;
  font-weight: bold;
  margin: 5px;
  cursor: pointer;
}

.tagSelectedItem {
  padding: 5px 10px;
  background-color: #fff;
  color: #999;
  font-weight: bold;
  border-radius: 5px;
  display: block;
  width: fit-content;
  margin: 10px;
  display: inline-block;
}

.select_cross {
  color: #333;
  margin: 5px 0px 5px 10px;
  border-radius: 50%;
  width: 50px;
  height: 10px;
  cursor: pointer;
}

.tab ul li {
  color: aliceblue;
  border-radius: 5px;
  background-color: #333;
  padding: 5px 15px;
  font-weight: bold;
  position: relative;
  display: inline;
  margin: 0px 10px;
  cursor: pointer;
}

.active {
  background-color: dodgerblue !important;
}

.grid {
  display: grid;
  grid-gap: 0rem;
  margin: 0 auto;
  margin-top: 0px;
  grid-template-columns: repeat(2, 1fr);
}

.gridSecond {
  display: grid;
  grid-gap: 0rem;
  margin: 0 auto;
  margin-top: 0px;
  grid-template-columns: repeat(2, 1fr);
}

.dummyItem {
  border-radius: 10px;
  border-collapse: separate;
  margin: 0px 0px;
  padding: 10px 20px;
}

.userInfo {
  margin: 20px 0px;
  color: aliceblue;
}
