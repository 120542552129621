// @import "~antd/dist/antd.less";

// @import "./styles/variables.less";
// @import "./styles/styles.less";

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

#components-form-demo-normal-login .login-form {
  max-width: 300px;
}
#components-form-demo-normal-login .login-form-forgot {
  float: right;
}
#components-form-demo-normal-login .login-form-button {
  width: 100%;
}

.site-layout .site-layout-background {
  background: #fff;
}

.ant-menu-title-content {
  flex: none !important;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@primary-color: #038fde;@secondary-color: #faBc15;@text-color: #545454;@text-color-secondary: #595959;@border-radius-base: 4px;@label-required-color: #28C1A3;@heading-color: #535353;@header-text-color: #26262;@layout-header-background: #fefefe;@layout-footer-background: #fffffe;@body-background: #f5f5f5;@nav-bar-bg: #fffffc;@nav-bar-text-color: @text-color;@hor-nav-text-color: #fffffd;@font-size: 14px;